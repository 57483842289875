import { InlineTextButton } from '../../components';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import css from './ListingPage.module.css';
import React from 'react';

const SectionHeading = (props) => {
    const {
        mediaIcon = null,
        priceTitle,
        formattedPrice,
        richTitle,
        category,
        authorLink,
        showContactUser,
        onContactUser,
    } = props;

    const unitType = config.lineItemUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
        ? 'ListingPage.perNight'
        : isDaily
        ? 'ListingPage.perDay'
        : 'ListingPage.perUnit';

    return (
        <div className={css.sectionHeading}>
            <div className={css.desktopPriceContainer}>
                <div className={css.desktopPriceValue} title={priceTitle}>
                    {formattedPrice}
                </div>
                <div className={css.desktopPerUnit}>
                    <FormattedMessage id={unitTranslationKey} />
                </div>
            </div>
            <div className={css.heading}>
                <h1 className={css.title}>{richTitle}</h1>
                <div className={css.author}>
                    {mediaIcon ? (
                        <>
                            {mediaIcon}
                            <span className={css.separator}>•</span>
                        </>
                    ) : null}

                    {category}
                    <FormattedMessage
                        id="ListingPage.soldBy"
                        values={{ name: authorLink }}
                    />
                    {showContactUser ? (
                        <span className={css.contactWrapper}>
                            <span className={css.separator}>•</span>
                            <InlineTextButton
                                rootClassName={css.contactLink}
                                onClick={onContactUser}
                                enforcePagePreloadFor="SignupPage">
                                <FormattedMessage id="ListingPage.contactUser" />
                            </InlineTextButton>
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SectionHeading;
