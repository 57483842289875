import { Reviews } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import React from 'react';

const SectionReviews = (props) => {
    const { reviews, fetchReviewsError } = props;

    const reviewsError = (
        <h2 className={css.errorText}>
            <FormattedMessage id="ListingPage.reviewsError" />
        </h2>
    );

    return (
        <div className={css.sectionReviews}>
            <h2 className={css.reviewsTitle}>
                <FormattedMessage
                    id="ListingPage.reviewsTitle"
                    values={{ count: reviews.length }}
                />
            </h2>
            {fetchReviewsError ? reviewsError : null}
            <Reviews reviews={reviews} />
        </div>
    );
};

export default SectionReviews;
