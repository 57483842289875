import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import css from './ListingPage.module.css';
import React from 'react';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = (props) => {
    const { description, listingTitle } = props;
    return description ? (
        <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
                <FormattedMessage
                    id="ListingPage.descriptionTitle"
                    values={{ listingTitle }}
                />
            </h2>
            <p className={css.description}>
                {richText(description, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                    longWordClass: css.longWord,
                })}
            </p>
        </div>
    ) : null;
};

export default SectionDescriptionMaybe;
