import { UserCard, Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import EnquiryForm from './EnquiryForm/EnquiryForm';
import css from './ListingPage.module.css';
import React from 'react';

const SectionAuthorMaybe = (props) => {
    const {
        title,
        listing,
        authorDisplayName,
        onContactUser,
        isEnquiryModalOpen,
        onCloseEnquiryModal,
        sendEnquiryError,
        sendEnquiryInProgress,
        onSubmitEnquiry,
        currentUser,
        onManageDisableScrolling,
    } = props;

    if (!listing.author) {
        return null;
    }

    return (
        <div id="author" className={css.sectionAuthor}>
            <h2 className={css.aboutProviderTitle}>
                <FormattedMessage id="ListingPage.aboutProviderTitle" />
            </h2>
            <UserCard
                user={listing.author}
                currentUser={currentUser}
                onContactUser={onContactUser}
            />
            <Modal
                id="ListingPage.enquiry"
                contentClassName={css.enquiryModalContent}
                isOpen={isEnquiryModalOpen}
                onClose={onCloseEnquiryModal}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}>
                <EnquiryForm
                    className={css.enquiryForm}
                    submitButtonWrapperClassName={
                        css.enquirySubmitButtonWrapper
                    }
                    listingTitle={title}
                    authorDisplayName={authorDisplayName}
                    sendEnquiryError={sendEnquiryError}
                    onSubmit={onSubmitEnquiry}
                    inProgress={sendEnquiryInProgress}
                />
            </Modal>
        </div>
    );
};

export default SectionAuthorMaybe;
