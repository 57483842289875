import { PropertyGroup } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import React from 'react';

const SectionFeaturesMaybe = (props) => {
    const { options, publicData, extendedDataKey } = props;
    if (!publicData || !extendedDataKey || !publicData[extendedDataKey]) {
        return null;
    }

    const selectedOptions =
        publicData && publicData[extendedDataKey]
            ? publicData[extendedDataKey]
            : [];
    return (
        <div className={css.sectionFeatures}>
            <h2 className={css.featuresTitle}>
                <FormattedMessage id="ListingPage.featuresTitle" />
            </h2>
            <PropertyGroup
                id="ListingPage.amenities"
                options={options}
                selectedOptions={selectedOptions}
                twoColumns={true}
            />
        </div>
    );
};

export default SectionFeaturesMaybe;
